<template>
  <Layout>
    <saas-layout
      :page-titles="pageTitle"
      :bread-crumb-items="breadcrumbItems"
      :connected="connected"
    >
      <template #connection-info>
        <saas-information
          :src="service.logo || ''"
          :connected="connected"
          :usage="service.category_name"
          :connect-func="activeConnection"
          :disconnect-func="showDisconnectConnection"
          :connected-date="connectedDate"
          :connected-total="usedApp"
        />
      </template>
      <template #connected-content>
        <div class="SaaSConnection">
          <div class="SaaSConnection__select-time">
            <select-time :month.sync="month" :year.sync="year" />
          </div>
          <div class="SaaSConnection__list-account">
            <list-account
              :dialog-state="dialogState"
              :date="selectedDate"
              :fetch-data="fetchListUser"
              :show-add-saas-account-dialog="showCreateDialog"
              :show-delete-dialog="showRemoveDialog"
              :custom-field="listAccountFields"
              :reload-table="reloadListAccountTable"
            />
          </div>
          <div class="SaaSConnection__contract-info">
            <contract-info :selected-date="selectedDate" />
          </div>
          <div class="SaaSConnection__expense-info">
            <expense-infomation-table
              :refresh="refreshContractTable"
              :selected-date="selectedDate"
            />
          </div>
          <saas-service-modal
            :dialog-id="dialogCreateId"
            :is-remove="false"
            :is-create="true"
            :fields="dialogAddFields"
            :service-name="service.name"
            :dialog-buttons="dialogCreateButtons"
            :reset-flag="resetCreateFlag"
            :reload-func="reloadAdd"
            :checked-values.sync="checkedValues"
            :check-all.sync="checkedAllAddUser"
            :search-value.sync="searchInputAdd"
          />
          <saas-service-modal
            :dialog-id="dialogRemoveId"
            :is-remove="true"
            :fields="dialogRemoveFields"
            :service-name="service.name"
            :dialog-buttons="dialogRemoveButtons"
            :reset-flag="resetRemoveFlag"
            :reload-func="reloadRemove"
            :checked-values.sync="checkedValues"
            :check-all.sync="checkedAllRemoveUser"
            :search-value.sync="searchInputRemove"
            :disable-rule="disableRuleFreee"
          />
          <disconnect-confirmation
            :id="disconnectDialogId"
            :confirm-disconnect-func="deactivateConnection"
          />
          <freee-choose-company
            v-if="fetchCompanyIds"
            :logo="service.logo"
            :dialog-id="dialogChooseCompany"
            :submit-func="handleAddUserFreee"
            :selected-company-id.sync="selectedFreeCompany"
          />
        </div>
      </template>
    </saas-layout>
  </Layout>
</template>
<script>
import Layout from '@/layouts/main/app'
import SaasLayout from '@/components/commons/common-saas-layout'
import SaasInformation from '@/components/saas/saas-connection-info'
import SaasServiceModal from '@/components/modals/saas-add-remove-modal'
import ListAccount from '@/components/tables/list-of-account-using-service-table'
import SelectTime from '@/components/saas/select-time'
import ContractInfo from '@/components/tables/contract-information-table'
import ExpenseInfomationTable from '@/components/tables/expense-infomation-table'
import DisconnectConfirmation from '@/components/modals/saas-disconnect-confirm-modal'
import FreeeChooseCompany from '@/components/modals/freee-choose-company-modal'
import { ALERT_TYPE } from '@/utils/constants'
import { freeeService } from '@/services/saas-service/freee-service'
import { saasCommonService } from '@/services/saas-service/saas-common-service'
import { mapActions, mapGetters } from 'vuex'
import { stringToDateJapan } from '@/utils/date-utils'
import { MESSAGES } from '@/utils/messages'
import get from 'lodash-es/get'

export default {
  name: 'Freee',
  components: {
    Layout,
    SaasLayout,
    SaasInformation,
    SaasServiceModal,
    ListAccount,
    SelectTime,
    ContractInfo,
    ExpenseInfomationTable,
    DisconnectConfirmation,
    FreeeChooseCompany,
  },
  data() {
    return {
      pageTitle: 'FREEE',
      serviceName: 'FREEE',
      breadcrumbItems: [
        {
          text: 'SaaS管理',
          href: '/customer/saas-management',
        },
        {
          text: '利用中のサービス',
          href: '/customer/saas-management',
        },
        {
          text: 'FREEE',
          active: true,
        },
      ],
      service: {},
      serviceId: '',
      connected: false,
      resetCreateFlag: false,
      resetRemoveFlag: false,
      dialogCreateId: 'dialog-create',
      dialogRemoveId: 'dialog-remove',
      dialogChooseCompany: 'dialog-choose-company',
      disconnectDialogId: 'dialog-disconnect-confirm',
      dialogState: freeeService.getDialogState(),
      month: '',
      year: '',
      authorizedCode: '',
      savedMetadata: {},
      dataReady: false,
      dialogAddFields: saasCommonService.getSaaSAddModalFields(),
      dialogRemoveFields: freeeService.getSaaSRemoveDialogFields(),
      listAccountFields: freeeService.getSaaSAccountFields(),
      reloadAdd: saasCommonService.getListIntegrateUsers,
      reloadRemove: saasCommonService.getListUserBySaas,
      checkedValues: [],
      refreshContractTable: false,
      checkedAllAddUser: false,
      searchInputAdd: '',
      checkedAllRemoveUser: false,
      searchInputRemove: '',
      fetchCompanyIds: false,
      selectedFreeCompany: '',
      reloadListAccountTable: false,
    }
  },
  computed: {
    ...mapGetters('commonSettings', ['apps']),
    ...mapGetters('saas', ['code']),
    connectedDate() {
      return stringToDateJapan(this.service.connect_time)
    },
    selectedDate() {
      if (this.month && this.year) {
        return `${this.month}-${this.year}`
      }
      return ''
    },
    dialogCreateButtons() {
      return [
        {
          title: 'キャンセル',
          variant: 'light',
          minWidth: 74,
          size: 'md',
        },
        {
          title: '保存',
          variant: 'primary',
          minWidth: 124,
          size: 'md',
          func: this.onSubmitAddDialog,
        },
      ]
    },
    dialogRemoveButtons() {
      return [
        {
          title: 'キャンセル',
          variant: 'light',
          minWidth: 74,
          size: 'md',
        },
        {
          title: '削除',
          variant: 'primary',
          minWidth: 124,
          size: 'md',
          func: this.onSubmitRemoveDialog,
        },
      ]
    },
    userIds() {
      return this.checkedValues.map((item) => item.id)
    },
    usedApp() {
      return this.$store.getters['saas/service'].member || 0
    },
  },
  async created() {
    this.serviceId = Number(this.$route.fullPath.split('/').pop())
    this.service = await saasCommonService.getDetailSaas(this.serviceId)
    this.setService(this.service)
    this.savedMetadata = await saasCommonService.getSavedMetadata(this.serviceId)
    this.setServiceMetadata(this.savedMetadata)
    this.connected = this.service.connect_time && this.service.is_active
    if (!this.connected) {
      this.resetFetchTime(this.serviceId)
    }
    this.dataReady = true
    if (!this.service) {
      await this.$router.push('/404')
    }
  },
  async mounted() {
    if (this.code) {
      this.authorizedCode = this.code
      this.resetState() // reset code received from external service
      this.resetFetchTime(this.serviceId)
    }
    if (this.authorizedCode) {
      await this.handleConnectFreee()
    }
  },
  methods: {
    ...mapActions('saas', [
      'setCallbackRoute',
      'resetState',
      'setService',
      'setServiceMetadata',
      'resetFetchTime',
    ]),
    ...mapActions('alert', ['displayAlert']),
    ...mapActions('loading', ['showManualLoading', 'hideManualLoading', 'enableLoading']),
    activeConnection() {
      // save current path
      this.setCallbackRoute(this.$route.fullPath)
      // get code from freee
      const redirectUrl = encodeURIComponent(`${process.env.VUE_APP_URL}/authorize`)
      window.open(`${process.env.VUE_APP_FREEE_AUTHORIZE_URL}${redirectUrl}`, '_self')
    },
    showDisconnectConnection() {
      this.$bvModal.show(this.disconnectDialogId)
    },
    async deactivateConnection() {
      // call api,auth
      const { success, message } = await saasCommonService.disconnectApp(this.service.id)
      if (!success) {
        this.displayAlert({
          type: ALERT_TYPE.ERROR,
          messages: message,
        })
        return
      }
      this.connected = false
      this.displayAlert({
        type: ALERT_TYPE.SUCCESS,
        messages: MESSAGES.SAAS_CONNECT.LK03,
      })
    },
    async handleConnectFreee() {
      // gen access token
      const postParams = {
        client_id: process.env.VUE_APP_FREEE_CLIENT_ID,
        client_secret: process.env.VUE_APP_FREEE_CLIENT_SECRET,
        code: this.authorizedCode,
        grant_type: 'authorization_code',
        redirect_uri: `${process.env.VUE_APP_URL}/authorize`,
      }
      this.enableLoading()
      this.showManualLoading()
      try {
        const result = await freeeService.getAccessToken(postParams)
        if (result.access_token) {
          result.auth_type = 'Authorization'
          result.instance_url = process.env.VUE_APP_FREEE_INSTANCE_URL
          result.token_type = 'Bearer'
          const res = await saasCommonService.connectApp(this.serviceId)
          if (!res.success) {
            this.displayAlert({
              type: ALERT_TYPE.ERROR,
              messages: res.messages || '',
            })
            return
          }
          await saasCommonService.addToken(
            this.service.id,
            { ...this.savedMetadata, ...result },
            true
          )
          this.service = await saasCommonService.getDetailSaas(this.service.id, true)
          this.setService(this.service)
          this.savedMetadata = await saasCommonService.getSavedMetadata(this.service.id, true)
          this.setServiceMetadata(this.savedMetadata)
          this.connected = true
          this.displayAlert({
            type: ALERT_TYPE.SUCCESS,
            messages: MESSAGES.SAAS_CONNECT.LK01,
          })
        } else {
          this.displayAlert({
            type: ALERT_TYPE.ERROR,
            messages: MESSAGES.COMMON.MSG15,
          })
        }
        this.hideManualLoading()
      } catch (e) {
        this.hideManualLoading()
      } finally {
        this.hideManualLoading()
      }
    },
    async fetchListUser() {
      const { success, message } = await freeeService.fetchListUserFreee(
        this.service.subscribe_apps_id
      )
      if (!success) {
        this.displayAlert({
          type: ALERT_TYPE.ERROR,
          messages: message,
        })
        return
      }
      this.refreshContractTable = !this.refreshContractTable
      this.displayAlert({
        type: ALERT_TYPE.SUCCESS,
        messages: MESSAGES.SAAS_CONNECT.LK04,
      })
    },
    showCreateDialog() {
      this.resetCreateFlag = !this.resetCreateFlag
      this.$bvModal.show(this.dialogCreateId)
    },
    showRemoveDialog() {
      this.resetRemoveFlag = !this.resetRemoveFlag
      this.$bvModal.show(this.dialogRemoveId)
    },
    async onSubmitAddDialog() {
      if (this.userIds.length === 0) {
        return
      }
      this.fetchCompanyIds = true
      // show dialog profiles
      setTimeout(() => this.$bvModal.show(this.dialogChooseCompany), 200)
    },
    async handleAddUserFreee() {
      if (!this.selectedFreeCompany) {
        return
      }
      let ids = this.userIds
      if (this.checkedAllAddUser) {
        const { items } = await saasCommonService.getListIntegrateUsers(
          this.service.subscribe_apps_id,
          1,
          null,
          null,
          this.searchInputAdd
        )
        ids = items.map((item) => item.id)
      }
      const { success, message } = await freeeService.addUserFreee(
        this.service.subscribe_apps_id,
        ids,
        this.selectedFreeCompany
      )
      if (!success) {
        this.displayAlert({
          type: ALERT_TYPE.ERROR,
          messages: message,
        })
        return
      }
      this.reloadListAccountTable = !this.reloadListAccountTable
      this.displayAlert({
        type: ALERT_TYPE.SUCCESS,
        messages: MESSAGES.SAAS_CONNECT.LK06,
      })
      this.$bvModal.hide(this.dialogChooseCompany)
      this.$bvModal.hide(this.dialogCreateId)
    },
    async onSubmitRemoveDialog() {
      if (this.userIds.length === 0) {
        return
      }
      this.showManualLoading()
      const dataRemoveArrs = await this.getDataRemove()
      if (dataRemoveArrs.length === 0) {
        this.$bvModal.hide(this.dialogRemoveId)
        return
      }
      const userByCompany = {}
      dataRemoveArrs.forEach((data) => {
        if (userByCompany[data.company_id]) {
          userByCompany[data.company_id].push(data.user_id)
        } else {
          userByCompany[data.company_id] = []
          userByCompany[data.company_id].push(data.user_id)
        }
      })
      const self = this
      Object.keys(userByCompany).forEach((key) => {
        // call api remove
        freeeService
          .deleteUserFreee(this.service.subscribe_apps_id, userByCompany[key], key)
          .then((response) => {
            const success = get(response, 'success', false)
            if (!success) {
              self.displayAlert({
                type: ALERT_TYPE.ERROR,
                messages: '',
              })
              this.hideManualLoading()
              return
            }
            self.reloadListAccountTable = !self.reloadListAccountTable
            self.$nextTick(() => {
              self.hideManualLoading()
              self.displayAlert({
                type: ALERT_TYPE.SUCCESS,
                messages: MESSAGES.SAAS_CONNECT.LK05,
              })
              self.$bvModal.hide(self.dialogRemoveId)
            })
          })
          .catch((error) => {
            self.hideManualLoading()
            self.displayAlert({
              type: ALERT_TYPE.ERROR,
              messages: get(error, 'message', ''),
            })
          })
      })
    },
    disableRuleFreee(data) {
      const id = get(data, 'freee_company_id', '')
      return id === null || id === ''
    },
    async getDataRemove() {
      const { items } = await saasCommonService.getListUserBySaas(
        this.serviceId,
        null,
        null,
        null,
        null,
        this.searchInputRemove
      )
      const dataRemoveArrs = []
      if (this.checkedAllRemoveUser) {
        items.map((item) => {
          if (get(item, 'freee_company.id', '')) {
            dataRemoveArrs.push({
              company_id: get(item, 'freee_company.id', ''),
              user_id: item.id,
            })
          }
        })
      } else {
        items.map((item) => {
          if (this.userIds.includes(item.id) && get(item, 'freee_company.id', '')) {
            dataRemoveArrs.push({
              company_id: get(item, 'freee_company.id', ''),
              user_id: item.id,
            })
          }
        })
      }
      return dataRemoveArrs
    },
  },
}
</script>
