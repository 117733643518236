import axiosEx from '@/configs/http/external-api-axios'
import { CORS_EVERY_WHERE_URL, STATUS_CODE } from '@/utils/constants'
import axiosIn from '@/configs/http/internal-api-axios'
import { convertToFormUrlencodedData } from '@/utils/object-helpers'
import get from 'lodash-es/get'
import store from '@/store/store'
import { saasCommonService } from '@/services/saas-service/saas-common-service'
import { MESSAGES } from '@/utils/messages'

const baseUrl = 'api/v1/service/freee'
let maxFailAllowFreee = 2

async function getAccessToken(formData) {
  const url = process.env.VUE_APP_FREEE_OAUTH_URL
  const response = await axiosEx
    .post(`${CORS_EVERY_WHERE_URL}${url}`, convertToFormUrlencodedData(formData))
    .then((res) => {
      maxFailAllowFreee = 2
      return res
    })
    .catch(() => {
      if (maxFailAllowFreee-- === 0) {
        maxFailAllowFreee = 2
        return { data: false }
      }
      return getAccessToken(formData)
    })
  if (!response) {
    return false
  }
  return response.data
}

async function fetchListUserFreee(subscribe_apps_id) {
  if (!subscribe_apps_id) {
    return
  }
  const url = `${baseUrl}/${subscribe_apps_id}`
  return axiosIn
    .get(url)
    .then(async (res) => {
      return needRefreshToken(res.data, () => fetchListUserFreee(subscribe_apps_id))
    })
    .catch((err) => {
      return err
    })
}

async function addUserFreee(subscribe_apps_id, userIds, company_id) {
  const url = `${baseUrl}/${subscribe_apps_id}/add`
  return axiosIn
    .post(url, { user_id: userIds.toString(), company_id })
    .then(async (res) => {
      const errorMessage = get(res.data, 'error', '')
      const statusCode = get(res.data, 'statusCode', '')
      if (errorMessage && statusCode === STATUS_CODE.BAD_REQUEST) {
        return handleError(errorMessage)
      }
      return needRefreshToken(res.data, () => addUserFreee(subscribe_apps_id, userIds, company_id))
    })
    .catch((err) => {
      return err
    })
}

async function deleteUserFreee(subscribe_apps_id, userIds, company_id) {
  if (!subscribe_apps_id) {
    return
  }
  const url = `${baseUrl}/${subscribe_apps_id}/remove`
  return axiosIn
    .delete(url, { data: { user_id: userIds.toString(), company_id } })
    .then(async (res) => {
      return needRefreshToken(res.data, () =>
        deleteUserFreee(subscribe_apps_id, userIds, company_id)
      )
    })
    .catch((err) => {
      return err
    })
}

async function getCompanyList(subscribe_apps_id) {
  if (!subscribe_apps_id) {
    return
  }
  const url = `${baseUrl}/${subscribe_apps_id}/companies`
  const response = await axiosIn
    .get(url)
    .then(async (res) => {
      return needRefreshToken(res.data, () => getCompanyList(subscribe_apps_id))
    })
    .catch((err) => {
      return err
    })
  if (!get(response, 'success', false)) {
    return {
      success: false,
      items: response,
    }
  }
  return {
    success: response.success,
    items: response.data || [],
  }
}

function getDialogState() {
  /*
  true function can be used
  */
  return {
    ADD: false,
    CREATE: false,
    INVITE: false,
    DELETE: true,
    FETCH: true,
  }
}

async function needRefreshToken(response, callback) {
  const statusCode = get(response, 'statusCode', '')
  if (statusCode !== STATUS_CODE.UNAUTHORIZED) {
    return response
  }
  await store.dispatch('loading/showManualLoading')
  try {
    const savedMetadata = store.getters['saas/savedMetadata']
    const service = store.getters['saas/service']
    const postData = {
      client_id: process.env.VUE_APP_FREEE_CLIENT_ID,
      client_secret: process.env.VUE_APP_FREEE_CLIENT_SECRET,
      refresh_token: savedMetadata.refresh_token,
      grant_type: 'refresh_token',
    }
    // sonar warning here, but it's ok
    const result = await getAccessToken(postData)
    if (!result) {
      await store.dispatch('loading/hideManualLoading')
      return {
        success: false,
        message: MESSAGES.COMMON.MSG15,
      }
    }
    const newMetadata = { ...savedMetadata, ...result }
    newMetadata.token_type = 'Bearer'
    await saasCommonService.addToken(service.id, newMetadata)
    await store.dispatch('saas/setServiceMetadata', newMetadata)
    await store.dispatch('loading/hideManualLoading')
    return callback()
  } catch (e) {
    await store.dispatch('loading/hideManualLoading')
  }
}

function getSaaSAccountFields() {
  return [
    { key: 'external_id', label: 'アカウントID', sortable: true },
    { key: 'freee_company_name', label: '会社ID', sortable: true },
    { key: 'name', label: 'ユーザー名', sortable: true },
    { key: 'email', label: 'メールアドレス', sortable: true },
    { key: 'integrate_info_user_code', label: '従業員ID', sortable: true },
    { key: 'integrate_info_name', label: '従業員', sortable: true },
    { key: 'integrate_info_department_name', label: '部署', sortable: true },
    { key: 'created_at', label: '連携日時', sortable: true },
    { key: 'linking', label: '連携方法', sortable: true },
    { key: 'integrate_info_status', label: 'ステータス', sortable: true },
  ]
}

function getSaaSRemoveDialogFields() {
  return [
    { key: 'checkbox', label: '', sortable: false },
    { key: 'external_id', label: 'アカウントID', sortable: true },
    { key: 'freee_company_name', label: '会社ID', sortable: true },
    { key: 'name', label: 'ユーザー名', sortable: true },
    { key: 'email', label: 'メールアドレス', sortable: true },
    { key: 'integrate_info_user_code', label: '従業員ID', sortable: true },
    { key: 'integrate_info_name', label: '従業員', sortable: true },
    { key: 'integrate_info_department_name', label: '部署', sortable: true },
    { key: 'created_at', label: '連携日時', sortable: true },
    { key: 'linking', label: '連携方法', sortable: true },
    { key: 'integrate_info_status', label: 'ステータス', sortable: true },
  ]
}

function handleError(messages) {
  const result = {
    success: false,
    message: '',
  }
  if (messages.indexOf(MESSAGES.SAAS_CONNECT.FR01) !== -1) {
    result.message = MESSAGES.SAAS_CONNECT.FR01
  }
  return result
}

export const freeeService = {
  getAccessToken,
  getDialogState,
  fetchListUserFreee,
  addUserFreee,
  deleteUserFreee,
  getCompanyList,
  getSaaSAccountFields,
  getSaaSRemoveDialogFields,
}
