<template>
  <c-modal :id="dialogId" title="会社ID選択" :buttons="dialogButtons">
    <div class="FreeeCompany">
      <div class="FreeeCompany__image">
        <img :src="logo || defaultCompanyLogo" alt="freee-image" />
      </div>
      <div class="FreeeCompany__label font-size-12">会社ID</div>
      <div class="FreeeCompany__select">
        <b-form-select
          id="select"
          v-model="value"
          class="form-select"
          size="sm"
          :options="options"
        />
      </div>
    </div>
  </c-modal>
</template>
<script>
import CModal from '@/components/commons/common-modal'
import { freeeService } from '@/services/saas-service/freee-service'
import { ALERT_TYPE, DEFAULT_COMPANY_LOGO } from '@/utils/constants'
import { mapActions, mapGetters } from 'vuex'
import get from 'lodash-es/get'

export default {
  name: 'FreeeCompanyModal',
  components: { CModal },
  props: {
    dialogId: {
      type: String,
      required: true,
    },
    submitFunc: {
      type: Function,
      default: () => {
        return {}
      },
    },
    selectedCompanyId: {
      type: String,
      required: true,
    },
    logo: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      defaultCompanyLogo: DEFAULT_COMPANY_LOGO,
      value: '',
      options: [],
      dialogTitle: '連携するチャンネル選択',
      dialogButtons: [
        {
          title: 'キャンセル',
          variant: 'light',
          minWidth: 74,
          size: 'md',
        },
        {
          title: '保存',
          variant: 'primary',
          minWidth: 133,
          size: 'md',
          func: this.submitFunc,
        },
      ],
    }
  },
  computed: {
    ...mapGetters('saas', ['service']),
  },
  watch: {
    value(newVal) {
      this.$emit('update:selectedCompanyId', String(newVal))
    },
  },

  async created() {
    this.showManualLoading()
    const { success, items } = await freeeService.getCompanyList(this.service.subscribe_apps_id)
    this.hideManualLoading()
    if (!success) {
      this.displayAlert({
        type: ALERT_TYPE.ERROR,
        messages: items.message,
      })
      return
    }
    this.options = items.map((item) => {
      return {
        value: item.id,
        text: item.name,
      }
    })
    // set first value as default value
    this.value = get(this.options[0], 'value', '')
    this.$emit('update:selectedCompanyId', String(this.value))
  },
  methods: {
    ...mapActions('loading', ['showManualLoading', 'hideManualLoading']),
    ...mapActions('alert', ['displayAlert']),
  },
}
</script>
<style lang="scss">
.FreeeCompany {
  &__image {
    text-align: center;
    padding-top: 4px;
    padding-bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      max-width: 70px;
      max-height: 70px;
      border-radius: 10px;
    }
  }
  &__label {
    font-weight: 500;
    margin-bottom: 10px;
  }
}
</style>
